import { useStorage } from "@vueuse/core";
import { PetTypes } from "@/types/emr";

const state = useStorage(
  "dp:register",
  {
    customer_address: {
      province: "",
      province_code: "",
    },
    pet_name: "",
    pet_type: "" as PetTypes,
    dob: "",
    pet_gender: "Male" as "Male" | "Female",
    breed: {
      id: null as number | null,
      name: "",
    },
    visit_reasons: [] as string[],
    selected_plan_id: null as number | null,
  },
  localStorage,
  { mergeDefaults: true }
);

export default () => state;
